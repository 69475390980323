import React from "react"
import FeatureBottom from "./featureBottom"
import FeautureTop from "./feautureTop"
import Footer from "./footer"
import "./layout.css"
import NavbarMenu from "./navbarMenu"
import SignUp from "./signUp"
import FeatureHighlightCard1 from "./featureHighlightCard1"
import FeatureHighlightCard2 from "./featureHighlightCard2"
import HeaderMenu from "./headerMenu.jsx"
import BodyWear from "./bodyWear"
import NavbarMobile from "./navbarMobile"
import PoweredByScience from "./poweredByScience"
import TopCarousel from "./topCarousel"
import MiddleCarousel from "./carouselMiddle"
import MiddleCarouselMobile from "./carouselMiddleMobile"
import PoweredByScienceMobile from "./poweredByScienceMobile"
import ExtraOptions from "./extraOptions"
import ExtraOptionsMedium from "./extraOptionsMedium"
import { Helmet } from "react-helmet"
import logoImage from "../../public/favicon-32x32.png"
import SEO from "./seo"
import TopCarouselMobile from "./topCarouselMobile"
import { ItemM6HomeData } from "./../landingPageData"

const PowerMeshLayout = () => {
  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content="Style and substance come together in ITEM m6's premium bodywear collection. Explore our collection of elevated women's shapewear, leggings, and more."
        />
        <meta property="og:image" content={logoImage} />
        <meta
          property="og:title"
          content="ITEM M6 | Next - Level Bodywear and Legwear"
        />
        <script
          type="text/javascript"
          async
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XSpbzZ"
        ></script>
      </Helmet>
      <SEO title="ITEM M6" />
      {/* <HeaderMenu /> */}
      <NavbarMenu />
      <NavbarMobile />
      <TopCarousel {...ItemM6HomeData.heroCarousel.mesh} />
      <TopCarouselMobile {...ItemM6HomeData.heroCarousel.mesh}/>
      {/*<FeautureTop />*/}
      <ExtraOptions {...ItemM6HomeData.allowToSurprise}/>
      <ExtraOptionsMedium {...ItemM6HomeData.allowToSurprise}/>
      <MiddleCarousel {...ItemM6HomeData.midCarousel.meshData}/>
      <MiddleCarouselMobile {...ItemM6HomeData.midCarousel.meshData}/>
      <PoweredByScience {...ItemM6HomeData.poweredByScience.mesh}/>
      <PoweredByScienceMobile {...ItemM6HomeData.poweredByScience.mesh}/>
      <FeatureHighlightCard1 {...ItemM6HomeData.highlightCard.mesh}/>
      <FeatureHighlightCard2 {...ItemM6HomeData.highlightCard.mesh}/>
      <BodyWear {...ItemM6HomeData.feelingGood}/>
      {/* <FeatureBottom /> */}
      {/*<HR />*/}
      <SignUp />
      <Footer />
    </>
  )
}

export default PowerMeshLayout
