import React from "react"
import { ThemeProvider } from "styled-components"
import PowerMeshLayout from '../components/powerMeshLayout'

const theme = {
  color: "#111",
  headerFontSize: "14px",
  footerTitleSize: "15px",
  footerTextSize: "14px",
}

const AllMesh = () => (
  <ThemeProvider theme={theme}>
    <PowerMeshLayout />
  </ThemeProvider>
)

export default AllMesh
